import { Component } from '@angular/core';

@Component({
  selector: 'app-previous-porject',
  templateUrl: './previous-porject.component.html',
  styleUrls: []
})
export class PreviousPorjectComponent  {



}
