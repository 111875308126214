<br/>
<br/>

<div class="container">
    <div class="row">

<div class="col-lg-7">
    <div class="projectDis_img">
    <img src="../assets/images/projectimg26-2.png" alt="10digi.com/vodafone"> 
       
    </div>
</div>

<div class="col-lg-5">
    <div class="portFDis">
            <div class="header_text">
                    <div class="text-left">
                            <img src="./assets/images/border-bottom-arrow-down.png"  alt="about-me-sandeep-kumar-saxena">
                        </div>
                       
                        Description
                          
            </div>

            <div class="pro_name">Name
                <br/>
                <span>KooB</span>
            </div>

            <div class="pro_name">country
                    <br/>
                    <span>India</span>
                </div>


              
                    <div class="pro_name">Description
                            <br/>
                            <span>To put it simply, KooB here to get your remote devices (and 'things') connected to the internet. </span>
                        </div>

                        <div class="pro_name">Color Themes
                                <br/>
                               <div class="color_themem">
                                   <ul>
                                       <li style="background:#181f8a;"></li>
                                       <li style="background:#4e6cff;"></li>
                                       <li style="background:#f3f3f3;"></li>
                                       <li style="background:#e6e6e6;"></li>
                                       <li style="background:#ccc;"></li>
                                   </ul>
                               </div>
                            </div>



                            <div class="pro_name">Font Family
                                    <br/>
                                    <span>DIN Next LT Pro, sans-serif</span>
                                </div>

                                <div class="pro_name">Skills
                                        <br/>
                                       <div class="skills_options">
                                           <ul>
                                                <li>XHTML</li>
                                                <li>HTML5</li>
                                                <li>CSS</li>
                                                <li>Bootstrap 3</li>
                                                <li>jQuery</li>
                                                <li>Javascript</li>
                                                
                                           </ul>
                                       </div>
                                    </div>

                                    
                                    <div class="pro_name">Responsive Design
                                            <br/>
                                            <span>Yes, with using media query</span>
                                        </div>

                                    


                                    <div class="pro_name">Software
                                            <br/>
                                           <div class="skills_options">
                                               <ul>
                                                   <li>Photoshop</li>
                                                   <li>illustrator</li>
                                                   <li>Notepad++</li>
                                                   <li>VS Code</li>
                                                  
                                               </ul>
                                           </div>
                                        </div>


                                        <div class="pro_name">Browser Support
                                                <br/>
                                               <div class="browser_support">
                                                   <ul>
                                                       <li><img src="../assets/images/chrome.png" alt="google"></li>
                                                       <li><img src="../assets/images/firefox.png" alt="google"></li>
                                                       <li><img src="../assets/images/internate-explore.png" alt="google"></li>
                                                       <li><img src="../assets/images/internate-explore-new.png" alt="google"></li>
                                                       <li><img src="../assets/images/opera.png" alt="google"></li>
                                                       <li><img src="../assets/images/safary-browser.png" alt="google"></li>
                                                   </ul>
                                               </div>
                                            </div>
                

                                        <div class="pro_name2">Website URL:
                                                <br/>
                                                <span><a target="_blank" href="https://www.koobridge.com/">https://www.koobridge.com/</a></span>
                                            </div>
        
    
        
    </div>    
</div>



    </div>
</div>