import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sundawn',
  templateUrl: './sundawn.component.html',

})
export class SundawnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
