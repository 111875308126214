<br/>
<br/>


<div class="container">

 
    <ol class="breadcrumb" style="margin-bottom:0px;"> 
      <li><a routerLink="/about-us" >About Us</a></li>
      <li class="active">My Best Projects</li>        
    </ol>
    <div class="row">
    <div class="col-md-12 text-left">
                                          <div class="header_text" style="padding:0px;">
                                                 <div class="text-left">
                                                         <img src="./assets/images/border-bottom-arrow-down.png"  alt="about-me-sandeep-kumar-saxena">
                                                     </div>
                                                    
                                                     My Best Projects
                                                       
                                         </div>
                                         </div> 
                                        </div>
  </div>

  <br/>


 
<div class="container">
  <div class="row">

    <div class="col-lg-4">
        <div class="projectListing">
        <div class="img_overlay">
          <div class="project_title text-center">Religare Health Insurance</div>
            <div class="col-md-6 col-md-offset-3 col-sm-offset-6 text-center project_details_btn">
                    <a routerLink="/project-description" class="view_my_profile">View Details</a>
            </div> 
        </div>  
        <img src="../assets/images/projectimg1.jpg" alt="projectimg1">
        </div>
        </div>
        
        
        <div class="col-lg-4">
            <div class="projectListing">
            <div class="img_overlay">
              <div class="project_title text-center">DCB Bank</div>
                <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                        <a routerLink="/banking-website" class="view_my_profile">View Details</a>
                </div> 
            </div>  
            <img src="../assets/images/projectimg2.jpg" alt="projectimg1">
            </div>
            </div>
        

    <div class="col-lg-4">
      <div class="projectListing">
      <div class="img_overlay">
        <div class="project_title text-center">SoftAge Software Solution</div>
          <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                  <a routerLink="/softage-project-description" class="view_my_profile">View Details</a>
          </div> 
      </div>  
      <img src="../assets/images/projectimg23.png" alt="projectimg1">
      </div>
      </div>

      <div class="col-lg-4">
        <div class="projectListing">
        <div class="img_overlay">
          <div class="project_title text-center">Softage Lastmile Delivery</div>
            <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                    <a routerLink="/softage-lastmile" class="view_my_profile">View Details</a>
            </div> 
        </div>  
        <img src="../assets/images/softage_lastmile_s.jpg" alt="projectimg1">
        </div>
        </div>

        <div class="col-lg-4">
          <div class="projectListing">
          <div class="img_overlay">
            <div class="project_title text-center">Softage Casestudy</div>
              <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                      <a routerLink="/softage-casestudy" class="view_my_profile">View Details</a>
              </div> 
          </div>  
          <img src="../assets/images/softage_care_s.jpg" alt="projectimg1">
          </div>
          </div>

   

          <div class="col-lg-4">
            <div class="projectListing">
            <div class="img_overlay">
              <div class="project_title text-center">10digi</div>
                <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                        <a routerLink="/tendigi-description" class="view_my_profile">View Details</a>
                </div> 
            </div>  
            <img src="../assets/images/projectimg24.png" alt="projectimg1">
            </div>
            </div>

            <div class="col-lg-4">
              <div class="projectListing">
              <div class="img_overlay">
                <div class="project_title text-center">10digi International SIM</div>
                  <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                          <a routerLink="/international-sim" class="view_my_profile">View Details</a>
                  </div> 
              </div>  
              <img src="../assets/images/10digi_inter_s.jpg" alt="projectimg1">
              </div>
              </div>

              
              <div class="col-lg-4">
                <div class="projectListing">
                <div class="img_overlay">
                  <div class="project_title text-center">Koobridge</div>
                    <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                            <a routerLink="/tendigi-vodafone-description" class="view_my_profile">View Details</a>
                    </div> 
                </div>  
                <img src="../assets/images/projectimg26.png" alt="projectimg1">
                </div>
                </div>


                <div class="col-lg-4">
                    <div class="projectListing">
                    <div class="img_overlay">
                      <div class="project_title text-center">Indian Cuisine Classes
                        </div>
                        <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                            <a routerLink="/journagetravels" class="view_my_profile">View Details</a>
                        </div> 
                    </div>  
                    <img src="../assets/images/full-screen03-2.png" alt="projectimg1">
                    </div>
                    </div>
                
                
                    <div class="col-lg-4">
                      <div class="projectListing">
                      <div class="img_overlay">
                        <div class="project_title text-center">Fernhill Resort Chail</div>
                          <div class="col-md-6 col-md-offset-3 col-sm-offset-6 text-center project_details_btn">
                              <a routerLink="/thegoldempire" class="view_my_profile">View Details</a>
                          </div> 
                      </div>  
                      <img src="../assets/images/full-screen04-2.png" alt="projectimg1">
                      </div>
                      </div>
              
              
              
              <div class="col-lg-4">
              <div class="projectListing">
              <div class="img_overlay">
                <div class="project_title text-center">Anam Tutors</div>
                  <div class="col-md-6 col-md-offset-3 col-sm-offset-6 text-center project_details_btn">
                      <a routerLink="/searchmyghar" class="view_my_profile">View Details</a>
                  </div> 
              </div>  
              <img src="../assets/images/full-screen05-2.png" alt="projectimg1">
              </div>
              </div>


              
        <div class="col-lg-4">
            <div class="projectListing">
            <div class="img_overlay">
              <div class="project_title text-center">Tripsbank</div>
                <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                        <a routerLink="/tripsbank" class="view_my_profile">View Details</a>
                </div> 
            </div>  
            <img src="../assets/images/projectimg4.jpg" alt="projectimg1">
            </div>
            </div>

                  
 
         
                                       
  </div>
</div>

<br/>
<br/>
<br/>

<router-outlet></router-outlet>  