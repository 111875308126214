import { Component } from '@angular/core';

@Component({
  selector: 'app-cambodia-travel-agency',
  templateUrl: './cambodia-travel-agency.component.html',

})
export class CambodiaTravelAgencyComponent {


}
