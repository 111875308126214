<br/>
<br/>

<div class="container">
    <div class="row">

<div class="col-lg-7">
    <div class="projectDis_img">
    <img src="../assets/images/10digi_inter_L.jpg" alt="softage.net"> 
       
    </div>
</div>

<div class="col-lg-5">
    <div class="portFDis">
            <div class="header_text">
                    <div class="text-left">
                            <img src="./assets/images/border-bottom-arrow-down.png"  alt="about-me-sandeep-kumar-saxena">
                        </div>
                       
                        Description
                          
            </div>

            <div class="pro_name">Name
                <br/>
                <span>10digi International SIM</span>
            </div>

            <div class="pro_name">country
                    <br/>
                    <span>India</span>
                </div>


              
                    <div class="pro_name">Description
                            <br/>
                            <span>SoftAge Information Technology Limited prides itself as one of the leaders in the field of Document Management Services</span>
                        </div>

                        <div class="pro_name">Color Themes
                                <br/>
                               <div class="color_themem_option23">
                                   <ul>
                                       <li></li>
                                       <li></li>
                                       <li></li>
                                       <li></li>
                                       <li></li>
                                   </ul>
                               </div>
                            </div>



                            <div class="pro_name">Font Family
                                    <br/>
                                    <span>Poppins, sans-serif</span>
                                </div>

                                <div class="pro_name">Skills
                                        <br/>
                                       <div class="skills_options">
                                           <ul>
                                                <li>Shopify</li>
                                           
                                                
                                           </ul>
                                       </div>
                                    </div>

                                    
                                    <div class="pro_name">Responsive Design
                                            <br/>
                                            <span>Yes</span>
                                        </div>

                                    


                                    <div class="pro_name">Software
                                            <br/>
                                           <div class="skills_options">
                                               <ul>
                                                   <li>Photoshop</li>
                                                   <li>illustrator</li>
                                                   <li>Notepad++</li>
                                                   <li>Dreamweaver</li>
                                                  
                                               </ul>
                                           </div>
                                        </div>


                                        <div class="pro_name">Browser Support
                                                <br/>
                                               <div class="browser_support">
                                                   <ul>
                                                       <li><img src="../assets/images/chrome.png" alt="google"></li>
                                                       <li><img src="../assets/images/firefox.png" alt="google"></li>
                                                       <li><img src="../assets/images/internate-explore.png" alt="google"></li>
                                                       <li><img src="../assets/images/internate-explore-new.png" alt="google"></li>
                                                       <li><img src="../assets/images/opera.png" alt="google"></li>
                                                       <li><img src="../assets/images/safary-browser.png" alt="google"></li>
                                                   </ul>
                                               </div>
                                            </div>
                

                                        <div class="pro_name2">Website URL:
                                                <br/>
                                                <span><a target="_blank" href="https://international.10digi.com/">international.10digi.com</a></span>
                                            </div>
        
    
        
    </div>    
</div>



    </div>
</div>