<br/>
<br/>


<div class="container">

 
    <ol class="breadcrumb" style="margin-bottom:0px;"> 
      <li><a routerLink="/about-us" >About Us</a></li>
      <li class="active">Catabatic technology</li>        
    </ol>
    <div class="row">
    <div class="col-md-12 text-left">
                                          <div class="header_text" style="padding:0px;">
                                                 <div class="text-left">
                                                         <img src="./assets/images/border-bottom-arrow-down.png"  alt="about-me-sandeep-kumar-saxena">
                                                     </div>
                                                    
                                                     Previous Project
                                                       
                                         </div>
                                         </div> 
                                        </div>
  </div>

  <br/>


 
<div class="container">
  <div class="row">

<div class="col-lg-4">
<div class="projectListing">
<div class="img_overlay">
  <div class="project_title text-center">Religare Health Insurance</div>
    <div class="col-md-6 col-md-offset-3 col-sm-offset-6 text-center project_details_btn">
            <a routerLink="/project-description" class="view_my_profile">View Details</a>
    </div> 
</div>  
<img src="../assets/images/projectimg1.jpg" alt="projectimg1">
</div>
</div>


<div class="col-lg-4">
    <div class="projectListing">
    <div class="img_overlay">
      <div class="project_title text-center">DCB Bank</div>
        <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                <a routerLink="/banking-website" class="view_my_profile">View Details</a>
        </div> 
    </div>  
    <img src="../assets/images/projectimg2.jpg" alt="projectimg1">
    </div>
    </div>


    <div class="col-lg-4">
        <div class="projectListing">
        <div class="img_overlay">
          <div class="project_title text-center">Cambodia Travel Agency</div>
            <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                    <a routerLink="/cambodia-travel-agency" class="view_my_profile">View Details</a>
            </div> 
        </div>  
        <img src="../assets/images/projectimg3.jpg" alt="projectimg1">
        </div>
        </div>


        <div class="col-lg-4">
            <div class="projectListing">
            <div class="img_overlay">
              <div class="project_title text-center">Tripsbank</div>
                <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                        <a routerLink="/tripsbank" class="view_my_profile">View Details</a>
                </div> 
            </div>  
            <img src="../assets/images/projectimg4.jpg" alt="projectimg1">
            </div>
            </div>


            <div class="col-lg-4">
                <div class="projectListing">
                <div class="img_overlay">
                  <div class="project_title text-center">Delhi Farms</div>
                    <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                            <a routerLink="/delhifarms" class="view_my_profile">View Details</a>
                    </div> 
                </div>  
                <img src="../assets/images/projectimg5.jpg" alt="projectimg1">
                </div>
                </div>

                <div class="col-lg-4">
                    <div class="projectListing">
                    <div class="img_overlay">
                      <div class="project_title text-center">PTC India Financial</div>
                        <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                            <a routerLink="/ptcindia-financial" class="view_my_profile">View Details</a>
                        </div> 
                    </div>  
                    <img src="../assets/images/projectimg6.jpg" alt="projectimg1">
                    </div>
                    </div>
                                       
  </div>
</div>


<router-outlet></router-outlet>  