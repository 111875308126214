import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-three-rcasa',
  templateUrl: './three-rcasa.component.html',
 
})
export class ThreeRcasaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
