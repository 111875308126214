import { Component } from '@angular/core';

@Component({
  selector: 'app-goldmine-developer',
  templateUrl: './goldmine-developer.component.html',
 
})
export class GoldmineDeveloperComponent  {


}
