import { Component } from '@angular/core';

@Component({
  selector: 'app-delhifarms',
  templateUrl: './delhifarms.component.html',

})
export class DelhifarmsComponent {



}
