import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thegoldempire',
  templateUrl: './thegoldempire.component.html',
  
})
export class ThegoldempireComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
