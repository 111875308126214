import { Component } from '@angular/core';

@Component({

  selector: 'app-project-description',
  templateUrl: './project-description.component.html',
  styleUrls: []

})

export class ProjectDescriptionComponent{

websiteUrl = "https://www.religarehealthinsurance.com/";



}
