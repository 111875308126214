import { Component } from '@angular/core';

@Component({
  selector: 'app-karsoo',
  templateUrl: './karsoo.component.html',

})
export class KarsooComponent  {



}
