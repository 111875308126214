<div class="clearfix"></div>


<div class="contact_banner">
  
   <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1750.8166511784523!2d77.03649786063517!3d28.640750432106408!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d050d9e482c2f%3A0x9d29a478d0cdd435!2sPeer+Baba+Rd%2C+Sai+Enclave%2C+Mohan+Garden%2C+Uttam+Nagar%2C+Delhi%2C+110059!5e0!3m2!1sen!2sin!4v1525769786294" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe> 
</div>




<div class="container">
  <div class="row">

    <div class="contact_box">

<div class="col-lg-12">

      <div class="header_text" style="padding:0 0 20px 0;">
         
            Contact Us  
        
           


  </div>
</div>







 


 
    <div class="col-lg-5">


    
        <div class="naviContac">
          <ul>
          <li>
          <i class="fa fa-clock-o"></i>
          <address>
          <label>Working Time</label>
          <span>8:00AM - 6:30PM</span>
          </address>
          </li>
          
          
          <li>
          <i class="fa fa-map-marker"></i>
          <address>
          <label>Visit Our Location</label>
          <span>Plon No. 35/1 G-3, Sai Enclave <br/>
            Mohan Garden Uttam Nagar, New Delhi - 110059</span>
          </address>
          </li>
          
          
          <li>
          <i class="fa fa-phone"></i>
          <address>
          <label>Have any query? Call us</label>
          <span>+91 920 565 1637</span>
          </address>
          </li>
          
    
          
          
          <li>
          <i class="fa fa-envelope-o"></i>
          <address>
          <label>Contact with Us</label>
          <span><a href="mailto:sandeepuiux.developer@gmail.com">sandeepuiux.developer@gmail.com</a></span>
          </address>
          </li>
          
          
          </ul>
          </div>
    
      
      </div>  
<div class="col-lg-7">
<div class="contactUs_container">

  <form [formGroup]="postRecruitment" (ngSubmit)="onSubmittwo()">
    <div class="form-group col-lg-6 col-sm-6">
      <label for="">Name</label>
      <input type="text" class="form-control" #clientName name="fullname" formControlName="fullname" id="fullname" >

<div class="form_tooltip" *ngIf="fullname.touched && fullname.invalid">
<span *ngIf="fullname.errors.required">Name can not be blank</span>
<span *ngIf="fullname.errors.spaceNotAllowed">Space can not be allowed</span>
</div>

</div>




    <div class="form-group col-lg-6 col-sm-6">
      <label for="">Email Id</label>
      <input type="email" class="form-control" #clientEmailId name="emailaddress" formControlName="emailaddress" id="emailaddress" >

 <div class="form_tooltip" *ngIf="emailaddress.touched && emailaddress.invalid ">
<span *ngIf="emailaddress.errors.required">Email address can not be blank</span>
<span *ngIf="emailaddress.errors.pattern">Email not valid. Please type valid email</span>
 </div>

    </div>

    <div class="form-group col-lg-6 col-sm-6">
      <label for="">Company</label>
      <input type="text" formControlName="companyname" #companyName name="companyname" class="form-control" id="companyname">

      <div class="form_tooltip" *ngIf="companyname.touched && companyname.invalid">
          <span *ngIf="companyname.errors.required">Company name can not be blank</span>
      </div>
     
    </div>

    <div class="form-group col-lg-6 col-sm-6">
      <label for="">Job Title</label>
      <input type="text"  class="form-control" #jobTitlePost name="jobtitle" formControlName="jobtitle" id="jobtitle" >
      <div  class="form_tooltip" *ngIf="jobtitle.touched && jobtitle.invalid" >
       <span *ngIf="jobtitle.errors.required" >Job title can not be blank</span>
      </div>
 
    </div>  




    <div class="form-group col-lg-12 col-sm-12">
      <label for="">Message</label>
     <textarea  class="form-control" name="message" #requiredMessage formControlName="message"  id="message" cols="1" rows="3" ></textarea>
     <div  class="form_tooltip" *ngIf="message.touched && message.invalid" >
        <span *ngIf="message.errors.required" >Message can not be blank</span>
       </div>
      
 
    </div>


<div class="col-lg-12 text-right">
    <div id="button_success"   class="thanks_mes alert alert-success text-center">{{submitMessage}}</div>
    <button type="submit" id="disa" [disabled]="postRecruitment.invalid" (click)="submess(clientName, clientEmailId, companyName, jobTitlePost, requiredMessage)" class="contact_submit">Submit</button>
    




</div>





  </form>

</div>
</div>









<br/>
<br/>

</div>

  </div>
</div>



<router-outlet></router-outlet> 