<br/>
<br/>




<div class="container">

 
    <ol class="breadcrumb" style="margin-bottom:0px;"> 
      <li><a routerLink="/about-us" >About Us</a></li>
      <li class="active">UI & UX Design</li>        
    </ol>
    <div class="row">
    <div class="col-md-12 text-left">
                                          <div class="header_text" style="padding:0px;">
                                                 <div class="text-left">
                                                         <img src="./assets/images/border-bottom-arrow-down.png"  alt="about-me-sandeep-kumar-saxena">
                                                     </div>
                                                    
                                                    UI & UX Design
                                                       
                                         </div>
                                         </div> 
                                        </div>
  </div>

  <br/>

<div class="container">
  <div class="row">


    <div class="col-lg-4">
      <div class="wireframe">
        <img src="./assets/images/wireframe-images/launcher.jpg"  alt="wireframe">
      </div>
        </div>

  

            <div class="col-lg-4">
              <div class="wireframe">
                <img src="./assets/images/wireframe-images/sign-in.jpg"  alt="wireframe">
              </div>
                </div>


                <div class="col-lg-4">
                  <div class="wireframe">
                    <img src="./assets/images/wireframe-images/sign-up.jpg"  alt="wireframe">
                  </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="wireframe">
                        <img src="./assets/images/wireframe-images/recoverd-password.jpg"  alt="wireframe">
                      </div>
                        </div>

                        <div class="col-lg-4">
                          <div class="wireframe">
                            <img src="./assets/images/wireframe-images/home.jpg"  alt="wireframe">
                          </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="wireframe">
                                <img src="./assets/images/wireframe-images/side-panel.jpg"  alt="wireframe">
                              </div>
                                </div>




                           

                            <div class="col-lg-4">
                              <div class="wireframe">
                                <img src="./assets/images/wireframe-images/products-listing.jpg"  alt="wireframe">
                              </div>
                                </div>

                                <div class="col-lg-4">
                                  <div class="wireframe">
                                    <img src="./assets/images/wireframe-images/products-description.jpg"  alt="wireframe">
                                  </div>
                                    </div>

                                    <div class="col-lg-4">
                                      <div class="wireframe">
                                        <img src="./assets/images/wireframe-images/shopping-card.jpg"  alt="wireframe">
                                      </div>
                                        </div>

                                        <div class="col-lg-4">
                                          <div class="wireframe">
                                            <img src="./assets/images/wireframe-images/shopping-details.jpg"  alt="wireframe">
                                          </div>
                                            </div>  



                                        <div class="col-lg-4">
                                          <div class="wireframe">
                                            <img src="./assets/images/wireframe-images/place-order.jpg"  alt="wireframe">
                                          </div>
                                            </div>  



                                        <div class="col-lg-4">
                                          <div class="wireframe">
                                            <img src="./assets/images/wireframe-images/payment-option.jpg"  alt="wireframe">
                                          </div>
                                            </div>  


                                        <div class="col-lg-4">
                                          <div class="wireframe">
                                            <img src="./assets/images/wireframe-images/payment-confirmation.jpg"  alt="wireframe">
                                          </div>
                                            </div>  






  </div>
</div>

<br/>
<br/> 

<router-outlet></router-outlet>   