import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-propcasa',
  templateUrl: './propcasa.component.html',

})
export class PropcasaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
