import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';



@Injectable()
export class UserPostServicesService {
  apiUrl = 'https://portfolio-8539f.firebaseio.com/companyDetails.json';
constructor(private http: HttpClient){}



 makePost(postField){
  return this.http.post(this.apiUrl, postField)
 }

 



}
