import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ominnovation',
  templateUrl: './ominnovation.component.html',

})
export class OminnovationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
