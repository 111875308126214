import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-current-project',
  templateUrl: './current-project.component.html',
  styles: []
})
export class CurrentProjectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
