


<div class="topBanner_bg">
        <div class="container">
                <div class="row">
                    <div class="col-md-6 col-md-offset-1 col-xs-8 about-us-form aboutgap text-uppercase fadeInLeft animated">
                <h1>Sandeep Kumar</h1>
                <strong>Saxena
                    <br/>

                    <div class="viewMyProject">
                        <a routerLink="/my-best-projects" target="_blank" >View My Best Project <i class="fa fa-angle-double-right"></i></a>
                        </div>
                        
                    <!-- <figure class="text-right hidden-xs"><img src="./assets/images/border-bottom-arrow.png" alt="border-bottom" /></figure> -->
            
                </strong>
                
                    </div>
            
            
            <div class="col-lg-4  col-xs-4 text-right">
                    <img src="../assets/images/dp3.png" class="myimg_width"  alt="sandeep kumar saxena">
            </div> 
        
            
                  </div> 
                </div>
        </div>


        <div class="clearfix"></div>


    
     <div class="container">
         <div class="row">
    <div class="col-md-12 text-center">
            <div class="header_text">
                    <div class="text-left">
                            <img src="./assets/images/border-bottom-arrow-down.png"  alt="about-me-sandeep-kumar-saxena">
                        </div>
                       
                About me
               
               
            </div>
        </div> 
    
             <div class="col-md-2 col-xs-4 col-sm-4">
             <div class="about_item text-center">
                     <a href="../assets/images/app-wireframe.pdf" target="_blank">

                                <figure><img src="./assets/images/website-design.png"  alt="website-design"></figure>
                                <figcaption>
                                    <h3>Wireframe <span>Design</span></h3>
                           
                           </figcaption>
                     </a>
        
             </div>
             </div>
    
    
    
             
    
                    <div class="col-md-2 col-xs-4 col-sm-4">
                            <div class="about_item text-center">
                                    <a routerLink="/ui-design" >

                                                <figure><img src="./assets/images/mobile-app-design.png"  alt="mobile-app-design"></figure>
                                                <figcaption>
                                                    <h3>Mobile App <span>Design</span></h3>                   
                                           </figcaption>
                                    </a>
                             
                            </div>
                            </div>
                            <div class="col-md-2 col-xs-4 col-sm-4">
                                <div class="about_item text-center">
                                        <a routerLink="/soft-age">
                                                    <figure><img src="./assets/images/website-development.png"  alt="website-development"></figure>
                                                    <figcaption>
                                                        <h3>Website <span>Development</span></h3>                   
                                               </figcaption>
                                        </a>
                            
                                </div>
                                </div>
    
                            <div class="col-md-2 col-xs-4 col-sm-4">
                                    <div class="about_item text-center">
                                            <a routerLink="/logo-design" >
                                                        <figure><img src="./assets/images/logo-design.png"  alt="logo-design"></figure>
                                                        <figcaption>
                                                            <h3>Logo <span>Design</span></h3>                   
                                                   </figcaption>        
                                            </a>
                                     
                                    </div>
                                    </div>
    
      
                                    <div class="col-md-2 col-xs-4 col-sm-4">
                                    <div class="about_item text-center">
                                            <a routerLink="/banner-design">
                                                        <figure><img src="./assets/images/banner-design.png"  alt="banner-design"></figure>
                                                        <figcaption>
                                                            <h3>Banner <span>Design</span></h3>                   
                                                   </figcaption>
                                            </a>
                                  
                                    </div>
                                    </div>
    
    
    
                                    <div class="col-md-2 col-xs-4 col-sm-4">
                                    <div class="about_item text-center">
                                            <a routerLink="/emailer-design">
                                                        <figure><img src="./assets/images/emailer-design.png"  alt="emailer-design"></figure>
                                                        <figcaption>
                                                            <h3>Emailer <span>Design</span></h3>                   
                                                   </figcaption>
                                            </a>
                                      
                                    </div>
                                    </div>
    
                                    <div class="clearfix"></div>
                                    
                                    <br/>
    
                                    <div class="col-md-4 about_highlisht">
                                            Hello, I'm Sandeep Kumar Saxena Creative Technologist & UI UX
    Designer, I create design product a more beautiful Mobile app designer.
                                    </div>
    
                                    <div class="col-md-4 about_highlisht_para text-justify text_custom">
                                        I have been 5 experiences in and UI/UX Design in IT E-commerce industry. Creating mobile app both Android and iOS, Well versed with Technology/Platform: wireframe, prototype, user research, user flow design, user problem solving, user journey design and creating user personas.
                                            
                                    </div>
    
    
                                    <div class="col-md-4 about_highlisht_para text-justify text_custom">
                                             Demonstrated skills in managing onsite and oﬀshore team members throughout the project lifecycle while maintaining coordination with stakeholders and providing them with regular updates about the project.
    Deft in performing live tracing with the client to identify the problem in live
    designs.
     
                                    </div>
                                              
                                    <div class="clearfix"></div> 
                                    <br/>
                                    <br/>
                                    
                                    <div class="col-md-4 col-md-offset-4 text-center">
                                        <a href="../assets/images/resumeDPF.pdf" target="_blank" class="view_my_profile">View My Profile</a>
                                    </div>
    
                                    <div class="clearfix"></div> 
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                            
         </div>
     </div> 



     <div class="UiUxDesign">

        <div class="container">
                <div class="row">
                                <div class="col-lg-6 col-xs-12">
                                                <h6>UI & UX Design</h6>
                                                <nav>
                                                        <ul>
                                                                <li><i></i>UX research</li>
                                                                <li><i></i>Collaboration</li>
                                                                <li><i></i>Wireframing and UI prototyping</li>
                                                                <li><i></i>UX writing</li>
                                                                <li><i></i>Visual communication</li>
                                                                <li><i></i>Interaction design</li>
                                                                <li><i></i>Analytics</li>
                                                        </ul>
                                                </nav>

                                                
                                                <div class="viewTab">
                                                                <a class="view_my_profile view_my_profileFull" routerLink="/wireframe" >View More</a>
                                                            </div>
                        
                                        </div>

                                        <div class="col-lg-6 col-xs-12 text-right paddingTop">
                                                        <img class="img-responsive" src="./assets/images/wireframe-images/UiUxDesign.png" alt="ux-design">
                                                </div>
                </div>
        </div>

        
    
               
              

</div>

<div class="clearfix"></div>
    
     <svg class="bigTriangleColor profile-section start-section-class" width="100%" height="150" viewBox="0 0 100 102" preserveAspectRatio="none">
                    <path d="M0 100 L30 10 L100 100 Z"></path>
            </svg>
    
     <div class="work_bg">
        <div class="container">
            <div class="row">
    <div class="col-md-12 text-center">
    
            <br/>
            <br/>
                <div class="header_text">
                    <div class="text-left">
                            <img src="./assets/images/border-bottom-arrow-down.png"  alt="about-me-sandeep-kumar-saxena">
                        </div>
                       
               Work flow 
                          
            </div>
    
    <br/>
    <br/>
    <br/>
    
        </div>
    
    
    
    <div class="flow_item_container">
    
    <div class="work_content">
            <div class="work_title" style="padding-top:0px;">Ready for work</div>
            <p class="work_para">I am ready for the project and planning for it.</p>
    </div>
    
    <div class="work_img">
            <img src="./assets/images/work-flow-1.png" alt="work-flow-item">
    </div>
            
    </div>
    
    
    <div class="col-lg-12 text-center hidden-xs">
    <img src="./assets/images/workflow-border.png" alt="work-flow">
    </div>
    
    <div class="flow_item_container2">

                <div class="work_content2 hidden-lg hidden-md hidden-sm">
                                <div class="work_title">Searching</div>
                                <p class="work_para">Searching for the needs and best reference for the project</p>
                        </div>
                        
    
            <div class="work_img2">
                    <img src="./assets/images/work-flow-2.png" alt="work-flow-item">
            </div>
    
            <div class="work_content2 hidden-xs">
                    <div class="work_title">Searching</div>
                    <p class="work_para">Searching for the needs and best reference for the project</p>
            </div>
            
           
                    
            </div>
    
            <div class="col-lg-12 text-center hidden-xs">
                    <img src="./assets/images/workflow-border-2.png" alt="work-flow">
                    </div>
                    
    
    
                    <div class="flow_item_container">
    
                            <div class="work_content">
                                    <div class="work_title">Planning</div>
                                    <p class="work_para">Planning for the project and working under it.</p>
                            </div>
                            
                            <div class="work_img">
                                    <img src="./assets/images/work-flow-3.png" alt="work-flow-item">
                            </div>
                                    
                            </div>
    
    
                            <div class="col-lg-12 text-center hidden-xs">
                                    <img src="./assets/images/workflow-border.png" alt="work-flow">
                                    </div>   
                                    
                                    <div class="flow_item_container2">

                                                <div class="work_content2 hidden-lg hidden-md hidden-sm">
                                                                <div class="work_title">Design</div>
                                                                <p class="work_para">Creating best user interface for the project</p>
                                                        </div>
    
                                            <div class="work_img2">
                                                    <img src="./assets/images/work-flow-4.png" alt="work-flow-item">
                                            </div>
                                    
                                            <div class="work_content2 hidden-xs">
                                                    <div class="work_title">Design</div>
                                                    <p class="work_para">Creating best user interface for the project</p>
                                            </div>
                                            
                                           
                                                    
                                            </div>    
                                            
                                            <div class="col-lg-12 text-center hidden-xs">
                                                    <img src="./assets/images/workflow-border-2.png" alt="work-flow">
                                                    </div>       
                                                    <div class="flow_item_container">
    
                                                            <div class="work_content">
                                                                    <div class="work_title">Launch</div>
                                                                    <p class="work_para">Preparation and launching of the project prematurely</p>
                                                            </div>
                                                            
                                                            <div class="work_img">
                                                                    <img src="./assets/images/work-flow-5.png" alt="work-flow-item">
                                                            </div>
                                                                    
                                                            </div>
                                    
    
    </div>
        </div>
    
    
        <br/>
        <br/>
     </div> 
    
    
    
    
    
    
    
    
    <div class="best_project">
                   <div class="sky_img"> <img src="../assets/images/bestproject-bg.png"  alt="bg"></div>
                           
    <div class="container-fluid">
    <div style="width:100%; float:left;">
            <app-slider></app-slider>     
    </div>
    </div>
    </div>
    
    <div class="clearfix"></div>
    
    
    
    <div class="container">
    
    <div class="row">
                   
                    <div class="col-md-12 text-center">
                                    <div class="header_text">
                                           <div class="text-left">
                                                   <img src="./assets/images/border-bottom-arrow-down.png"  alt="about-me-sandeep-kumar-saxena">
                                               </div>
                                              
                                               Profile
                                                 
                                   </div>
                                   </div>   
                                   
                                   
                                   <div class="col-md-12">
                                           <span class="sub_heading">Experience</span>
                                   </div>
    
    
                                   <div class="col-md-4">
                                            <div class="exp_work addcss">             
                                            <figure>
                                           
                                            </figure>
                                            
                                            <figcaption>
                                            <code>SoftAge Information Technology Limited</code>
                                            <span>UI UX Designer
                                            <br/>
                                            <b>June 2017 - Present</b>        
                                            </span> 
                                            </figcaption>
                                            
                                            </div>
                                            
                                            
                                              </div>
    
    
                                   
    
      
                                     
    <div class="col-md-4">
                    <div class="exp_work addcss">             
                    <figure>
                   
                    </figure>
                    
                    <figcaption>
                    <code>Zooneto Infosoft Private Limited</code>
                    <span>UI UX Designer and User Interface Developer
                    <br/>
                    <b>2016 - 2017</b>        
                    </span> 
                    </figcaption>
                    
                    </div>
                    
                    
                      </div>
    
    
                      <div class="col-md-4">
                                    <div class="exp_work addcss">             
                                    <figure>
                               
                                    </figure>
                                    
                                    <figcaption>
                                    <code>Catabatic Technology Pvt. Ltd.</code>
                                    <span>UI UX Designer and Responsive Design
                                    <br/>
                                    <b>2015 - 2016</b>        
                                    </span> 
                                    </figcaption>
                                    
                                    </div>
                                    
                                    
                                      </div>  
                                      
                                                     
    <div class="clearfix"></div>
    
    <div class="col-md-12">
    <span class="sub_heading">Skills</span>
    </div>
    
    <div class="skills_container">
    
                    <div class="col-lg-4">
    
                            <div class="skills_label">Wireframing
                                    <span style="float:right;">97%</span>
                            </div>
    
                                    <div class="skill_box_out">
                                            <div class="skills_box_inside"></div>
                                    </div>
                                    
                                     </div>    
                                     
                                     
                                     <div class="col-lg-4">
    
                                                    <div class="skills_label skills_label2">User Flow Design
                                                            <span style="float:right;">95%</span>
                                                    </div>
                            
                                                            <div class="skill_box_out">
                                                                    <div class="skills_box_inside skills_box_inside2"></div>
                                                            </div>
                                                            
                                                             </div>    
    
    
                                                             <div class="col-lg-4">
    
                                                                            <div class="skills_label skills_label3 ">Prototyping
                                                                                    <span style="float:right;">95%</span>
                                                                            </div>
                                                    
                                                                                    <div class="skill_box_out">
                                                                                            <div class="skills_box_inside skills_box_inside3"></div>
                                                                                    </div>
                                                                                    
                                                                                     </div>    
    
                                                                                     <div class="col-lg-4">
    
                                                                                            <div class="skills_label skills_label4">User Experience Design
                                                                                                    <span style="float:right;">80%</span>
                                                                                            </div>
                                                                    
                                                                                                    <div class="skill_box_out">
                                                                                                            <div class="skills_box_inside skills_box_inside4"></div>
                                                                                                    </div>
                                                                                                    
                                                                                                     </div>  
     <div class="col-lg-4">
    
          <div class="skills_label skills_label5">UX Research
          <span style="float:right;">80%</span>
       </div>
                                                                                    
    <div class="skill_box_out">
            <div class="skills_box_inside skills_box_inside5"></div>
            </div>
                                                                                                                    
       </div> 
       
       <div class="col-lg-4">
    
            <div class="skills_label skills_label6">Figma
            <span style="float:right;">95%</span>
         </div>
                                                                                      
      <div class="skill_box_out">
              <div class="skills_box_inside skills_box_inside6"></div>
              </div>
                                                                                                                      
         </div> 
         
         <div class="col-lg-4">
    
            <div class="skills_label skills_label7">Adobe XD
            <span style="float:right;">95%</span>
         </div>
                                                                                      
      <div class="skill_box_out">
              <div class="skills_box_inside skills_box_inside7"></div>
              </div>
                                                                                                                      
         </div>  
    
                      
          <div class="col-lg-4">
    
            <div class="skills_label skills_label8">Photoshop
            <span style="float:right;">90%</span>
         </div>
                                                                                      
      <div class="skill_box_out">
              <div class="skills_box_inside skills_box_inside8"></div>
              </div>
                                                                                                                      
         </div>  
         
                        
         <div class="col-lg-4">
    
            <div class="skills_label skills_label9">Illustrator
            <span style="float:right;">99%</span>
         </div>
                                                                                      
      <div class="skill_box_out">
              <div class="skills_box_inside skills_box_inside9"></div>
              </div>
                                                                                                                      
         </div> 




         <div class="col-lg-4">
    
                <div class="skills_label skills_label10">Balsamiq    
                <span style="float:right;">90%</span>
             </div>
                                                                                          
          <div class="skill_box_out">
                  <div class="skills_box_inside skills_box_inside10"></div>
                  </div>
                                                                                                                          
             </div> 


             <div class="col-lg-4">
    
                <div class="skills_label skills_label11">Zeplin
                <span style="float:right;">80%</span>
             </div>
                                                                                          
          <div class="skill_box_out">
                  <div class="skills_box_inside skills_box_inside11"></div>
                  </div>
                                                                                                                          
             </div> 


             <div class="col-lg-4">
    
                <div class="skills_label skills_label12">Invision
                <span style="float:right;">80%</span>
             </div>
                                                                                          
          <div class="skill_box_out">
                  <div class="skills_box_inside skills_box_inside12"></div>
                  </div>
                                                                                                                          
             </div> 
         
         
         <div class="clearfix"></div>
    
    <br/>
    <br/>
    <br/>
         <div class="col-md-4 col-md-offset-4 text-center">
                    <a class="view_my_profile" target="_blank" href="../assets/images/resumeDPF.pdf">Download Resume</a>
                </div>
    
    
    </div> 
    
    
    
    
                            
    
    
    
    </div>
    
    </div>   
    
    
    
    
    <svg class="bigTriangleColor profile-section start-section-class" height="150" preserveAspectRatio="none" viewBox="0 0 100 102" width="100%">
            <path d="M0 100 L30 10 L100 100 Z"></path>
    </svg>
    
    
    <div class="academic_bg">
    
    <div class="diploma_icon"><img src="./assets/images/diploma-icon.png" alt="diploma"></div>
    
    <div class="container">
    
            <div class="row">
    
                            <div class="col-md-12 text-center">
                                            <div class="header_text">
                                                   <div class="text-left">
                                                           <img src="./assets/images/border-bottom-arrow-down.png"  alt="about-me-sandeep-kumar-saxena">
                                                       </div>
                                                      
                                                     Diploma 
                                                         
                                           </div>
                                           </div>   
    
    
     
                                      
    <div class="col-md-8 col-lg-offset-2 text-center">
                    <div class="exp_work">             
                    <figure>
                   
                    </figure>
                    
                    <figcaption>
                    <code>Institute for Print & Web Technologies</code>
                    <span>Diploma in website designing, learning website desiging skills, adobe photoshop, css, html, bootstrap frame work, jquery, javascript and responsie design website.
                    <br/>
                    <b>2014 - 2015</b>        
                    </span> 
                    </figcaption>
                    
                    </div>
    
    <div class="clearfix"></div>
    <br/>
    <br/>
                    <div class="col-md-6 col-md-offset-3 text-center">
                                    <a href="../assets/images/certifucate.pdf" target="_blank" class="view_my_profile">View Certificate</a>
                                </div>
                    
                    
                      </div>
                                           
    
    
    </div>
    
    </div>
    
    
    <svg class="bigTriangleColor profile-section end-section-class" height="150" preserveAspectRatio="none" viewBox="0 0 100 102" width="100%">
            <path d="M0 0 L70 100 L100 0 Z"></path>
    </svg> 
    
    </div>
    
    <footer>
    
    <div class="container">
            <div class="row">
    
                            <div class="col-md-12 text-center">
                                            <div class="header_text paddingBt">
                                                   <div class="text-left">
                                                           <img src="./assets/images/border-bottom-arrow-down.png"  alt="about-me-sandeep-kumar-saxena">
                                                       </div>
                                                      
                                                     Contact 
                                                         
                                           </div>
                                           </div> 
    <div class="email_contact"><a href="mailto:sandeepuiux.developer@gmail.com">sandeepuiux.developer@gmail.com</a></div>    
      <div class="tell_contact"><a href="tel:9205651637">+91 9205651637</a></div>                                     
    
    
          <nav>     
                  <ul>
                          <li><a href="https://www.youtube.com/channel/UCUevxYRYOniWt9Fj0WU_s3w" target="_blank"><i class="fa fa-youtube"></i></a></li>
                          <li><a href="https://www.facebook.com/sandeep.saxena.1447342" target="_blank"><i class="fa fa-facebook"></i></a></li>
                          <li><a href="https://twitter.com/sandeepwebdesig" target="_blank"><i class="fa fa-twitter"></i></a></li>
                          <li><a href="https://www.linkedin.com/in/sandeep-kumar-41a656118/" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                          <li><a href="https://in.pinterest.com/sandeepk9953/" target="_blank"><i class="fa fa-pinterest "></i></a></li>
                  </ul>
          </nav>
                                           
    
            </div>
    </div>
            
    </footer> 
    
    <div class="clearfix"></div>
    
    
    <div class="footer_bottom"><i class="fa fa-copyright"></i> Copyright 2020-2021</div>


    