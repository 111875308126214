import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tendigi-store',
  templateUrl: './tendigi-store.component.html',
  styles: []
})
export class TendigiStoreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
