import { Component } from '@angular/core';

@Component({
  selector: 'app-tripsbank',
  templateUrl: './tripsbank.component.html',

})
export class TripsbankComponent  {



}
