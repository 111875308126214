import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui-design',
  templateUrl: './ui-design.component.html',
  styleUrls: []
})
export class UiDesignComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
