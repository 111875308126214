

 <div class="sliderContainer">
   <div class="imagedesktop hidden-xs">
    <img src="../assets/images/screenBg.png" alt="bg">
  </div>



  <!-- resposive slider -->
  <div id="carousel-example-generic-res" class="carousel-fade carousel slide hidden-lg hidden-md hidden-sm" data-ride="carousel">
   
    <div class="carousel-inner" role="listbox">


    
     

        <div class="item active project_content hidden-lg hidden-md hidden-sm">


            <div class="res_img_slider">
                    <img src="../assets/images/relImg.png" alt="sliderimg1">  
                 </div>


                 <strong>Best <span>Project</span></strong>

                 <p class="text-right">Religare health insurance is a specialized Health Insurer offering health insurance products to employees of corporates, individual customers and 
                   financial inclusion.</p>


                   <div class="col-md-6 col-xs-12  text-center" style="padding:0px; width:100%; float:left;">
                        <a href="https://www.religarehealthinsurance.com/" target="_blank" class="view_my_profile">View Website</a>
                    </div>



    </div>




    <div class="item project_content hidden-lg hidden-md hidden-sm">


        <div class="res_img_slider">
                <img src="../assets/images/dcbImg.png" alt="sliderimg1">  
             </div>


             <strong>Best <span>Project</span></strong>

             <p class="text-right">DCB Bank Ltd. is a private sector scheduled commercial bank in India. It has a network of 310 branches and 515 ATMs in the country</p>


               <div class="col-md-6 col-xs-12  text-center" style="padding:0px; width:100%; float:left;">
                    <a href="https://www.dcbbank.com/" target="_blank" class="view_my_profile">View Website</a>
                </div>



</div>



<div class="item project_content hidden-lg hidden-md hidden-sm">


    <div class="res_img_slider">
            <img src="../assets/images/temImg.png" alt="sliderimg1">  
         </div>


         <strong>Best <span>Project</span></strong>

         <p class="text-right">10digi is a ecommerce based company it's delivered at home sim connections postpaid, prepaid, mobile number portability and data card</p>


           <div class="col-md-6 col-xs-12  text-center" style="padding:0px; width:100%; float:left;">
                <a href="https://10digi.com/" target="_blank" class="view_my_profile">View Website</a>
            </div>



</div>








  
    
    </div>


    <ol class="carousel-indicators">
        <li data-target="#carousel-example-generic-res" data-slide-to="0" class="active"></li>
        <li data-target="#carousel-example-generic-res" data-slide-to="1"></li>
        <li data-target="#carousel-example-generic-res" data-slide-to="2"></li>
      </ol>

  </div>



  <!--responsive slider -->



  <div id="carousel-example-generic" class="carousel-fade carousel slide hidden-xs" data-ride="carousel">
   
  
     
      <div class="carousel-inner" role="listbox">


        
        <div class="item active">

<div class="slidertext">



  <div class="col-md-10 about-us-form  col-md-offset-2 project_content">
    

    <strong>Best <span>Project</span></strong>




<p class="text-right">Religare health insurance is a specialized Health Insurer offering health insurance products to employees of corporates, individual customers and 
  financial inclusion.</p>


  <div class="col-md-6  text-center" style="padding:0px;">
      <a href="https://www.religarehealthinsurance.com/" target="_blank" class="view_my_profile">View Website</a>
  </div>
   
    
        </div>



</div>

            <div class="screen_overlay">
               <img src="../assets/images/sliderimg1.png" alt="sliderimg1">  
            </div>


        </div>


       



        <div class="item">

          <div class="slidertext">
          
          
          
              <div class="col-md-10 about-us-form  col-md-offset-2 project_content">
    


                  <strong>Best <span>Project</span></strong>
                  
                  <p>DCB Bank Ltd. is a private sector scheduled commercial bank in India. It has a network of 310 branches and 515 ATMs in the country</p>
                  
                  
                  <div class="col-md-6   text-center" style="padding:0px;">
                      <a href="https://www.dcbbank.com/" target="_blank" class="view_my_profile">View Website</a>
                  </div>
                      
                          </div>
          
          
          
          </div>
          
                      <div class="screen_overlay">
                      <img src="../assets/images/sliderimg2.png" alt="Los Angeles">  
                      </div>
          
          
                  </div>

                 <div class="item">

                    <div class="slidertext">
                    
                    
                    
                        <div class="col-md-10 about-us-form  col-md-offset-2 project_content">
    


                            <strong>Best <span>Project</span></strong>
                            
                            <p>
                              10digi is a ecommerce based company it's delivered at home sim connections postpaid, prepaid, mobile number portability and data card
                              </p>
                            
                              <div class="col-md-6   text-center" style="padding:0px;">
                                  <a href="https://10digi.com/" target="_blank" class="view_my_profile">View Website</a>
                              </div>
                               
                                
                                    </div>
                    
                    
                    
                    </div>
                    
                                <div class="screen_overlay">
                                    <img src="../assets/images/sliderimg3.png" alt="sliderimg1">  
                                </div>
                    
                    
                            </div>
                
   
      
    
      
      </div>
 

      <ol class="carousel-indicators">
          <li data-target="#carousel-example-generic" data-slide-to="0" class="active"></li>
          <li data-target="#carousel-example-generic" data-slide-to="1"></li>
          <li data-target="#carousel-example-generic" data-slide-to="2"></li>
        </ol>

    </div>


 
  
  </div>



