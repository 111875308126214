import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  openNav() {
    document.getElementById("mySidenav").classList.add("navWidth");
}

closeNav() {
    document.getElementById("mySidenav").classList.remove("navWidth");
}


navClick(){


    // var x = document.getElementById("mySidenav");
    // if (x.style.width === "25%")
    //  {

    //   x.style.width = "0";
    // } 
    
    // else {
    //   x.style.width = "25%";
    // }


    document.getElementById("mySidenav").classList.remove("navWidth");


}


}
