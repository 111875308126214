import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gausidhartham',
  templateUrl: './gausidhartham.component.html',
  
})
export class GausidharthamComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
