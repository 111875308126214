import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http'
import { RouterModule, Routes } from '@angular/router';


import { AppComponent } from './app.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { PreviousPorjectComponent } from './previous-porject/previous-porject.component';
import { CurrentProjectComponent } from './current-project/current-project.component';
import { UiDesignComponent } from './ui-design/ui-design.component';
import { BannerDesignComponent } from './banner-design/banner-design.component';
import { LogoDesignComponent } from './logo-design/logo-design.component';
import { EmailerDesignComponent } from './emailer-design/emailer-design.component';
import { ProjectDescriptionComponent } from './project-description/project-description.component';
import { BankingWebsiteComponent } from './banking-website/banking-website.component';
import { CambodiaTravelAgencyComponent } from './cambodia-travel-agency/cambodia-travel-agency.component';
import { TripsbankComponent } from './tripsbank/tripsbank.component';
import { DelhifarmsComponent } from './delhifarms/delhifarms.component';
import { PtcindiaFinancialComponent } from './ptcindia-financial/ptcindia-financial.component';
import { KarsooComponent } from './karsoo/karsoo.component';
import { GoldmineDeveloperComponent } from './goldmine-developer/goldmine-developer.component';
import { PropcasaComponent } from './propcasa/propcasa.component';
import { AdebookingComponent} from './adebooking/adebooking.component';
import { ZoonetoinfosoftComponent } from './zoonetoinfosoft/zoonetoinfosoft.component';
import { PrateekGrandCityComponent } from './prateek-grand-city/prateek-grand-city.component';
import { JournagetravelsComponent } from './journagetravels/journagetravels.component';
import { ThegoldempireComponent } from './thegoldempire/thegoldempire.component';
import { SearchmygharComponent } from './searchmyghar/searchmyghar.component';
import { GausidharthamComponent } from './gausidhartham/gausidhartham.component';
import { NoidarealtyforumComponent } from './noidarealtyforum/noidarealtyforum.component';
import { ThreeRcasaComponent } from './three-rcasa/three-rcasa.component';
import { SundawnComponent } from './sundawn/sundawn.component';
import { WisteriaWeddingComponent } from './wisteria-wedding/wisteria-wedding.component';
import { OminnovationComponent } from './ominnovation/ominnovation.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ContactReactiveComponent } from './contact-reactive/contact-reactive.component';


import { SliderComponent } from './slider/slider.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UserPostServicesService } from './contact-reactive/contactpost.service';
import { WireframeComponent } from './wireframe/wireframe.component';
import { SoftAgeComponent } from './soft-age/soft-age.component';
import { DailynewskabharDescriptionComponent } from './dailynewskabhar-description/dailynewskabhar-description.component';
import { SoftageProjectDescriptionComponent } from './softage-project-description/softage-project-description.component';
import { TendigiDescriptionComponent } from './tendigi-description/tendigi-description.component';
import { TendigiBlogDescriptionComponent } from './tendigi-blog-description/tendigi-blog-description.component';
import { TendigiVodafoneDescriptionComponent } from './tendigi-vodafone-description/tendigi-vodafone-description.component';
import { AlsalconstructionDescriptionComponent } from './alsalconstruction-description/alsalconstruction-description.component';
import { InternationalSimComponent } from './international-sim/international-sim.component';
import { TendigiStoreComponent } from './tendigi-store/tendigi-store.component';
import { SoftageLastmileComponent } from './softage-lastmile/softage-lastmile.component';
import { SoftageBlogComponent } from './softage-blog/softage-blog.component';
import { SoftageCasestudyComponent } from './softage-casestudy/softage-casestudy.component';
import { MyBestProjectsComponent } from './my-best-projects/my-best-projects.component';





const appRoutes: Routes = [

  { path:'about-us', component:AboutUsComponent},
  { path:'soft-age', component:SoftAgeComponent},
  { path:'previous-porject', component:PreviousPorjectComponent},
  { path:'current-project', component:CurrentProjectComponent},
  { path:'ui-design', component:UiDesignComponent},
  { path:'banner-design', component:BannerDesignComponent},
  { path:'logo-design', component:LogoDesignComponent},
  { path:'emailer-design', component:EmailerDesignComponent},
  { path:'project-description', component:ProjectDescriptionComponent},
  { path:'banking-website', component:BankingWebsiteComponent},
  { path:'cambodia-travel-agency', component:CambodiaTravelAgencyComponent},
  { path:'tripsbank', component:TripsbankComponent},
  { path:'delhifarms', component:DelhifarmsComponent},
  { path:'ptcindia-financial', component:PtcindiaFinancialComponent},
  { path:'karsoo', component:KarsooComponent},
  { path:'goldmine-developer', component:GoldmineDeveloperComponent},
  { path:'propcasa', component:PropcasaComponent},
  { path:'adebooking', component:AdebookingComponent},
  { path:'zoonetoinfosoft', component:ZoonetoinfosoftComponent},
  { path:'prateek-grand-city', component:PrateekGrandCityComponent},
  { path:'journagetravels', component:JournagetravelsComponent},
  { path:'thegoldempire', component:ThegoldempireComponent},
  { path:'searchmyghar', component:SearchmygharComponent},
  { path:'gaursidhartham', component:GausidharthamComponent},
  { path:'noidarealtyforum', component:NoidarealtyforumComponent},
  { path:'three-rcasa', component:ThreeRcasaComponent},
  { path:'sundawn', component:SundawnComponent},
  { path:'wisteria-wedding', component:WisteriaWeddingComponent},
  { path:'oninnovation', component:OminnovationComponent},
  { path:'contact-reactive', component:ContactReactiveComponent},
  { path:'wireframe', component:WireframeComponent},
  { path:'softage-project-description', component:SoftageProjectDescriptionComponent},
  { path:'dailynewskabhar-description', component:DailynewskabharDescriptionComponent},
  { path:'tendigi-description', component:TendigiDescriptionComponent},
  { path:'tendigi-blog-description', component:TendigiBlogDescriptionComponent},
  { path:'tendigi-vodafone-description', component:TendigiVodafoneDescriptionComponent},
  { path:'alsalconstruction-description', component:AlsalconstructionDescriptionComponent},
  { path:'slider', component:SliderComponent},
  { path:'softage-blog', component:SoftageBlogComponent },
  { path:'softage-lastmile', component:SoftageLastmileComponent },
  { path:'softage-casestudy', component:SoftageCasestudyComponent },
  { path:'international-sim', component:InternationalSimComponent },
  { path:'tendigi-store', component:TendigiStoreComponent },
  { path:'my-best-projects', component:MyBestProjectsComponent },
  { path:'', redirectTo:"/about-us", pathMatch:"full"},
  { path:'page-not-found', component:PageNotFoundComponent},
  { path: '**', redirectTo: '/page-not-found' }



]


@NgModule({
  declarations: [
    AppComponent,
    AboutUsComponent,
    PreviousPorjectComponent,
    CurrentProjectComponent,
    UiDesignComponent,
    BannerDesignComponent,
    LogoDesignComponent,
    EmailerDesignComponent,
    ProjectDescriptionComponent,
    BankingWebsiteComponent,
    CambodiaTravelAgencyComponent,
    TripsbankComponent,
    DelhifarmsComponent,
    PtcindiaFinancialComponent,
    KarsooComponent,
    GoldmineDeveloperComponent,
    PropcasaComponent,
    AdebookingComponent,
    ZoonetoinfosoftComponent,
    PrateekGrandCityComponent,
    JournagetravelsComponent,
    ThegoldempireComponent,
    SearchmygharComponent,
    GausidharthamComponent,
    NoidarealtyforumComponent,
    ThreeRcasaComponent,
    SundawnComponent,
    WisteriaWeddingComponent,
    OminnovationComponent,
    ContactUsComponent,
    ContactReactiveComponent,
    SliderComponent,
    PageNotFoundComponent,
    WireframeComponent,
    SoftAgeComponent,
    DailynewskabharDescriptionComponent,
    SoftageProjectDescriptionComponent,
    TendigiDescriptionComponent,
    TendigiBlogDescriptionComponent,
    TendigiVodafoneDescriptionComponent,
    AlsalconstructionDescriptionComponent,
    InternationalSimComponent,
    TendigiStoreComponent,
    SoftageLastmileComponent,
    SoftageBlogComponent,
    SoftageCasestudyComponent,
    MyBestProjectsComponent


  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes)




  ],
  providers: [UserPostServicesService],
  bootstrap: [AppComponent]
})





export class AppModule {






}
