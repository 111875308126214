import { Component } from '@angular/core';

import { AbstractControl } from "@angular/forms";

export class CustomValidation{

   static spaceNotAllowed(control: AbstractControl): {[key : string]: boolean} | null {

        if( (control.value as string).indexOf(' ')> 0)
        
        return {spaceNotAllowed:true}
        
          return null
        
        
        }

}