import { FormGroup, FormControl, Validators, AbstractControl, FormArray } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { CustomValidation } from './custom.validation'
import { UserPostServicesService } from '../contact-reactive/contactpost.service';





@Component({
  selector: 'app-contact-reactive',
  templateUrl: './contact-reactive.component.html',
  styles: []
})
export class ContactReactiveComponent implements OnInit {

  companyPost:any[];

  postRecruitment = new FormGroup({
    
    fullname: new FormControl('', [
      Validators.required,
      CustomValidation.spaceNotAllowed

    ]),

    emailaddress: new FormControl('',[

Validators.required,
Validators.pattern("[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})")
    ]),

    companyname: new FormControl('', Validators.required),
    jobtitle: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),

  //   username: new FormControl('', [
  //     Validators.required,
  //   ]),

  // skillname: new FormArray([


  // ]),

    
  })


  adskills(sk:HTMLInputElement){
   
(this.postRecruitment.get('skillname') as FormArray).push( new FormControl(sk.value))
sk.value = '';

  }


  




  webOptions =[

'UI Designer', 'UI Developer', 'Web and Graphic Design', 'Front End Developer'


  ]


      


  get fullname(){
    return this.postRecruitment.get('fullname');
 ;
  }

  get emailaddress(){
return this.postRecruitment.get('emailaddress');
  }

  get companyname(){
return this.postRecruitment.get('companyname');
  }

  get jobtitle(){
return this.postRecruitment.get('jobtitle');
  
  }

  get message(){
    return this.postRecruitment.get('message');
  }


  get username(){
    return this.postRecruitment.get('username');
  }



  onSubmittwo(){
    
    console.log(this.postRecruitment);

  
  }
  


      submitMessage = ''
      submess(clientName: HTMLInputElement, clientEmailId:HTMLInputElement, companyName: HTMLInputElement, jobTitlePost: HTMLInputElement, requiredMessage: HTMLTextAreaElement){
    
        let postField = {
          cliName: clientName.value,
          cliEmailId: clientEmailId.value,
          comName: companyName.value,
          postJobTi: jobTitlePost.value,
          requMessage: requiredMessage.value

        }


      

        this.contactcompanyservice.makePost(postField).subscribe(
          response => {
           
           this.companyPost.splice(0,0, postField)
          }
        )



    this.submitMessage=' Thanks for shortlist for me ';
    document.getElementById('button_success').style.display = 'block';

    document.getElementById('disa').setAttribute("disabled","disabled");
    
    setTimeout(() => {     
      document.getElementById('button_success').style.display = 'none';
      
      }, 5000);



  
  
    
    }


    constructor( private contactcompanyservice: UserPostServicesService ) {



     }

    ngOnInit() {
    }
  




  

}
