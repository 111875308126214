
        <div id="mySidenav" class="sidenav">
                <button type="button" class="closebtn" (click)="closeNav()">x</button>
                <ul>
                <li><a routerLink="/about-us" (click)="navClick()">About Us</a></li>
                <li><a routerLink="/soft-age" (click)="navClick()">SoftAge <span style="font-size: 10px;" class="badge">Company</span></a></li>
                <li><a routerLink="/current-project" (click)="navClick()">Zooneto Info Soft <span style="font-size: 10px;" class="badge">Company</span></a></li>
                <li><a routerLink="/previous-porject" (click)="navClick()">Catabatic Technology <span style="font-size: 10px;" class="badge">Company</span></a></li>
                <li><a routerLink="/ui-design" (click)="navClick()">UI Design</a></li>
                <li><a routerLink="/banner-design" (click)="navClick()">Banner Design</a></li>
                <li><a routerLink="/logo-design" (click)="navClick()">Logo Design</a></li>
                <li><a routerLink="/emailer-design" (click)="navClick()">Emailer Design</a></li>
            <li><a routerLink="/contact-reactive" (click)="navClick()">Contact Us</a></li> 
            <!-- <li><a routerLink="/page-not-found" (click)="navClick()">Page Not Found</a></li>  -->
            
            <!-- <li><a routerLink="/slider" (click)="navClick()">Slider</a></li> -->
                <!-- <li><a routerLink="/test-reactive" (click)="navClick()">test Contact Us</a></li> -->
              </ul>
              </div>
    
            
         <div class="container">
              <div class="row">
                <div class="headerTop">
            
            <div class="col-md-4 col-xs-6">
              <a routerLink="/about-us">
         <img src="./assets/images/sandeep_kumar_saxena_logo.png" class="my_logo_height" alt="sandeep kumar saxena" />  
          </a>
            </div>
            
            
            <div class="col-md-4 col-md-offset-4 col-xs-6 text-right">
                    <div class="menu-icon">
                        <img src="./assets/images/menu-icon.png" (click)="openNav()" alt="toggle" />
                      </div>
                    </div>
            
            
                </div>
              </div>
            </div>

      
            
            <router-outlet></router-outlet> 


       
  
          
        
        
        
        
        
        