import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prateek-grand-city',
  templateUrl: './prateek-grand-city.component.html',
  
})
export class PrateekGrandCityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
