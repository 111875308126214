import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tendigi-vodafone-description',
  templateUrl: './tendigi-vodafone-description.component.html',
  styles: []
})
export class TendigiVodafoneDescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
