import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-searchmyghar',
  templateUrl: './searchmyghar.component.html',

})
export class SearchmygharComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
