import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tendigi-description',
  templateUrl: './tendigi-description.component.html',
  styles: []
})
export class TendigiDescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
