import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-journagetravels',
  templateUrl: './journagetravels.component.html',
  
})
export class JournagetravelsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
