import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wireframe',
  templateUrl: './wireframe.component.html',
  styles: []
})
export class WireframeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
