<br/>
<br/>

<div class="container">
    <div class="row">

<div class="col-lg-7">
    <div class="projectDis_img">
    <img src="../assets/images/projectimg17-2.jpg" alt="gaurssidhartham"> 
       
    </div>
</div>

<div class="col-lg-5">
    <div class="portFDis">
            <div class="header_text">
                    <div class="text-left">
                            <img src="./assets/images/border-bottom-arrow-down.png"  alt="about-me-sandeep-kumar-saxena">
                        </div>
                       
                        Description
                          
            </div>

            <div class="pro_name">Name
                <br/>
                <span>Wisteria Weddings</span>
            </div>

            <div class="pro_name">Country
                    <br/>
                    <span>India</span>
                </div>


              
                    <div class="pro_name">Description
                            <br/>
                            <span>Wisteria also symbolize memory. We at wisteria wedding plan elegant, timeless and signature weddings</span>
                        </div>

                        <div class="pro_name">Color Themes
                                <br/>
                               <div class="color_themem_option20">
                                   <ul>
                                       <li></li>
                                       <li></li>
                                       <li></li>
                                       <li></li>
                                       <li></li>
                                       
                                   </ul>
                               </div>
                            </div>



                            <div class="pro_name">Font Family
                                    <br/>
                                    <span>Open Sans, sans-serif</span>
                                </div>

                                <div class="pro_name">Skills
                                        <br/>
                                       <div class="skills_options">
                                           <ul>
                                                <li>XHTML</li>
                                                <li>HTML5</li>
                                                <li>CSS</li>
                                                <li>jQuery</li>
                                                <li>Javascript</li>
                                                
                                           </ul>
                                       </div>
                                    </div>

                                    
                                    <div class="pro_name">Responsive Design
                                            <br/>
                                            <span>Yes, using media query</span>
                                        </div>

                                    


                                    <div class="pro_name">Software
                                            <br/>
                                           <div class="skills_options">
                                               <ul>
                                                   <li>Photoshop</li>
                                                   <li>illustrator</li>
                                                   <li>Notepad++</li>
                                                 
                                                  
                                               </ul>
                                           </div>
                                        </div>


                                        <div class="pro_name">Browser Support
                                                <br/>
                                               <div class="browser_support">
                                                   <ul>
                                                       <li><img src="../assets/images/chrome.png" alt="google"></li>
                                                       <li><img src="../assets/images/firefox.png" alt="google"></li>
                                                       <!-- <li><img src="../assets/images/internate-explore.png" alt="google"></li> -->
                                                       <li><img src="../assets/images/internate-explore-new.png" alt="google"></li>
                                                       <li><img src="../assets/images/opera.png" alt="google"></li>
                                                       <li><img src="../assets/images/safary-browser.png" alt="google"></li>
                                                   </ul>
                                               </div>
                                            </div>
                

                                        <div class="pro_name2">Website URL:
                                                <br/>
                                                <span><a target="_blank" href="http://wisteriaweddings.in/home">http://wisteriaweddings.in/home</a></span>
                                            </div>
        
    
        
    </div>    
</div>



    </div>
</div>

<br/>
<br/>