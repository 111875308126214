import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-softage-project-description',
  templateUrl: './softage-project-description.component.html',
  styles: []
})
export class SoftageProjectDescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
