<br/>
<br/>




<div class="container">

 
    <ol class="breadcrumb" style="margin-bottom:0px;"> 
      <li><a routerLink="/about-us" >About Us</a></li>
      <li class="active">Banner Design</li>        
    </ol>
    <div class="row">
    <div class="col-md-12 text-left">
                                          <div class="header_text" style="padding:0px;">
                                                 <div class="text-left">
                                                         <img src="./assets/images/border-bottom-arrow-down.png"  alt="about-me-sandeep-kumar-saxena">
                                                     </div>
                                                    
                                                    Banner Design
                                                       
                                         </div>
                                         </div> 
                                        </div>
  </div>

  <br/>


<div class="container">
  <div class="row">

   <!--mobile resposive banner-->   


   <!-- web and social media banners -->


   <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/all-plan-one-click.png" alt="banner-design">
    </div>
  </div>

  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/carousel-slide-01.jpg" alt="banner-design">
    </div>
  </div>


  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/cashback.jpg" alt="banner-design">
    </div>
  </div>


  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/faster-and-smooth-2-dl.jpg" alt="banner-design">
    </div>
  </div>


  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/instagrame.png" alt="banner-design">
    </div>
  </div>


  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/instagrame-facebook-organic.jpg" alt="banner-design">
    </div>
  </div>

  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/instant-activation.png" alt="banner-design">
    </div>
  </div>


  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/jio4g_phone.jpg" alt="banner-design">
    </div>
  </div>


  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/micro-usb.jpg" alt="banner-design">
    </div>
  </div>



  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/mnp-generic02.jpg" alt="banner-design">
    </div>
  </div>

  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/mnp-in-just-2-days.jpg" alt="banner-design">
    </div>
  </div>


  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/mobile-number-porting.png" alt="banner-design">
    </div>
  </div>


  
  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/mob-micro.jpg" alt="banner-design">
    </div>
  </div>



  
  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/mob-type-c.jpg" alt="banner-design">
    </div>
  </div>


  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/port-to-airtel.jpg" alt="banner-design">
    </div>
  </div>



  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/port-to-bsnl.jpg" alt="banner-design">
    </div>
  </div>


  
  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/port-to-idea.jpg" alt="banner-design">
    </div>
  </div>


  
  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/port-to-jio.jpg" alt="banner-design">
    </div>
  </div>


  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/port-to-vodafone.jpg" alt="banner-design">
    </div>
  </div>


  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/postpaid-benefits.png" alt="banner-design">
    </div>
  </div>





  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/three-USBs-option2.jpg" alt="banner-design">
    </div>
  </div>


  <div class="col-lg-6 col-xs-6 col-sm-6">
    <div class="banner_design">
      <img src="../assets/images/social_media_banners/type-c-usb.jpg" alt="banner-design">
    </div>
  </div>


<div class="col-lg-6 col-xs-6 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/social_media_banners/diwali-instagram.png" alt="banner-design">
  </div>
</div>

<div class="col-lg-6 col-xs-6 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/social_media_banners/dussehra_social_post.png" alt="banner-design">
  </div>
</div>


<div class="col-lg-6 col-xs-6 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/social_media_banners/independence-day.png" alt="banner-design">
  </div>
</div>



<div class="col-lg-6 col-xs-6 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/social_media_banners/raksha-bandhan.png" alt="banner-design">
  </div>
</div>

<!-- web banner start -->

<div class="col-lg-6 col-xs-12 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/web_banners/10digi-store-new.jpg" alt="banner-design">
  </div>
</div>



<div class="col-lg-6 col-xs-12 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/web_banners/airtel-postpaid-new02.jpg" alt="banner-design">
  </div>
</div>



<div class="col-lg-6 col-xs-12 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/web_banners/bangalore-city-banner-mobile.jpg" alt="banner-design">
  </div>
</div>



<div class="col-lg-6 col-xs-12 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/web_banners/bsnl-mtnl-banner-desktop.jpg" alt="banner-design">
  </div>
</div>



<div class="col-lg-6 col-xs-12 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/web_banners/discount-banner-spe.jpg" alt="banner-design">
  </div>
</div>



<div class="col-lg-6 col-xs-12 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/web_banners/doorstep-activation-king.jpg" alt="banner-design">
  </div>
</div>



<div class="col-lg-6 col-xs-12 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/web_banners/every_customer_pri_spe.jpg" alt="banner-design">
  </div>
</div>



<div class="col-lg-6 col-xs-12 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/web_banners/free_sim_delivery.png" alt="banner-design">
  </div>
</div>



<div class="col-lg-6 col-xs-12 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/web_banners/idea-prepaid-new.jpg" alt="banner-design">
  </div>
</div>



<div class="col-lg-6 col-xs-12 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/web_banners/jio_banner.png" alt="banner-design">
  </div>
</div>


<div class="col-lg-6 col-xs-12 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/web_banners/paypal_banner00002.png" alt="banner-design">
  </div>
</div>


<div class="col-lg-6 col-xs-12 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/web_banners/plan-info.png" alt="banner-design">
  </div>
</div>

<div class="col-lg-6 col-xs-12 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/web_banners/thankyou-bsnl-banner.jpg" alt="banner-design">
  </div>
</div>

<div class="col-lg-6 col-xs-12 col-sm-6">
  <div class="banner_design">
    <img src="../assets/images/web_banners/vodafone-prepaid-new.jpg" alt="banner-design">
  </div>
</div>


  <!-- web and social media banners -->




   <div class="col-lg-6">
      <div class="banner_design">
        <img src="../assets/images/mobile-banner/banner1.png" alt="banner-design">
      </div>
    </div>

    <div class="col-lg-6">
        <div class="banner_design">
          <img src="../assets/images/mobile-banner/banner2.png" alt="banner-design">
        </div>
      </div>

      
      <div class="col-lg-6">
          <div class="banner_design">
            <img src="../assets/images/mobile-banner/banner3.png" alt="banner-design">
          </div>
        </div>
        <div class="col-lg-6">
            <div class="banner_design">
              <img src="../assets/images/mobile-banner/banner4.png" alt="banner-design">
            </div>
          </div>

          <div class="col-lg-6">
              <div class="banner_design">
                <img src="../assets/images/mobile-banner/banner5.png" alt="banner-design">
              </div>
            </div>

            <div class="col-lg-6">
                <div class="banner_design">
                  <img src="../assets/images/mobile-banner/banner6.png" alt="banner-design">
                </div>
              </div>

              <div class="col-lg-6">
                  <div class="banner_design">
                    <img src="../assets/images/mobile-banner/banner7.png" alt="banner-design">
                  </div>
                </div>

                <div class="col-lg-6">
                    <div class="banner_design">
                      <img src="../assets/images/mobile-banner/banner8.png" alt="banner-design">
                    </div>
                  </div>

                  <div class="col-lg-6">
                      <div class="banner_design">
                        <img src="../assets/images/mobile-banner/banner9.png" alt="banner-design">
                      </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner_design">
                          <img src="../assets/images/mobile-banner/banner10.png" alt="banner-design">
                        </div>
                      </div>

                      <div class="col-lg-6">
                          <div class="banner_design">
                            <img src="../assets/images/mobile-banner/banner11.png" alt="banner-design">
                          </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="banner_design">
                              <img src="../assets/images/mobile-banner/banner12.png" alt="banner-design">
                            </div>
                          </div>

                          <div class="col-lg-6">
                              <div class="banner_design">
                                <img src="../assets/images/mobile-banner/banner13.png" alt="banner-design">
                              </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="banner_design">
                                  <img src="../assets/images/mobile-banner/banner14.png" alt="banner-design">
                                </div>
                              </div>
                          

      <!--mobile resposive banner-->   





  <!--facebook page banner-->

  <div class="col-lg-6">
      <div class="banner_design">
        <img src="../assets/images/facebook-banner/banner.png" alt="banner-design">
      </div>
    </div>

    <div class="col-lg-6">
        <div class="banner_design">
          <img src="../assets/images/facebook-banner/banner02.png" alt="banner-design">
        </div>
      </div>

      <div class="col-lg-6">
          <div class="banner_design">
            <img src="../assets/images/facebook-banner/banner3.png" alt="banner-design">
          </div>
        </div>
  
        <div class="col-lg-6">
            <div class="banner_design">
              <img src="../assets/images/facebook-banner/banner4.png" alt="banner-design">
            </div>
          </div>
  
          <div class="col-lg-6">
              <div class="banner_design">
                <img src="../assets/images/facebook-banner/banner5.png" alt="banner-design">
              </div>
            </div>
  
            <div class="col-lg-6">
                <div class="banner_design">
                  <img src="../assets/images/facebook-banner/banner6.png" alt="banner-design">
                </div>
              </div>
  
              <div class="col-lg-6">
                  <div class="banner_design">
                    <img src="../assets/images/facebook-banner/banner7.png" alt="banner-design">
                  </div>
                </div>
  
                <div class="col-lg-6">
                    <div class="banner_design">
                      <img src="../assets/images/facebook-banner/banner8.png" alt="banner-design">
                    </div>
                  </div>
  
                  <div class="col-lg-6">
                      <div class="banner_design">
                        <img src="../assets/images/facebook-banner/banner9.png" alt="banner-design">
                      </div>
                    </div>
  
                    <div class="col-lg-6">
                        <div class="banner_design">
                          <img src="../assets/images/facebook-banner/banner10.png" alt="banner-design">
                        </div>
                      </div>
  
                      <div class="col-lg-6">
                          <div class="banner_design">
                            <img src="../assets/images/facebook-banner/banner11.png" alt="banner-design">
                          </div>
                        </div>
  
                        <div class="col-lg-6">
                            <div class="banner_design">
                              <img src="../assets/images/facebook-banner/banner12.png" alt="banner-design">
                            </div>
                          </div>
  
                          <div class="col-lg-6">
                              <div class="banner_design">
                                <img src="../assets/images/facebook-banner/banner13.png" alt="banner-design">
                              </div>
                            </div>
  
                            <div class="col-lg-6">
                                <div class="banner_design">
                                  <img src="../assets/images/facebook-banner/banner14.png" alt="banner-design">
                                </div>
                              </div>
  
                              <div class="col-lg-6">
                                  <div class="banner_design">
                                    <img src="../assets/images/facebook-banner/banner15.png" alt="banner-design">
                                  </div>
                                </div>
  
                                <div class="col-lg-6">
                                    <div class="banner_design">
                                      <img src="../assets/images/facebook-banner/banner16.png" alt="banner-design">
                                    </div>
                                  </div>
  
                                  <div class="col-lg-6">
                                      <div class="banner_design">
                                        <img src="../assets/images/facebook-banner/banner17.png" alt="banner-design">
                                      </div>
                                    </div>
  
                                    <div class="col-lg-6">
                                        <div class="banner_design">
                                          <img src="../assets/images/facebook-banner/banner18.png" alt="banner-design">
                                        </div>
                                      </div>
  
                                    
  
                                        <div class="col-lg-6">
                                            <div class="banner_design">
                                              <img src="../assets/images/facebook-banner/banner20.png" alt="banner-design">
                                            </div>
                                          </div>
  
                                          <div class="col-lg-6">
                                              <div class="banner_design">
                                                <img src="../assets/images/facebook-banner/banner21.png" alt="banner-design">
                                              </div>
                                            </div>
  
                                            <div class="col-lg-6">
                                                <div class="banner_design">
                                                  <img src="../assets/images/facebook-banner/banner22.png" alt="banner-design">
                                                </div>
                                              </div>
  
                                              <div class="col-lg-6">
                                                  <div class="banner_design">
                                                    <img src="../assets/images/facebook-banner/banner23.png" alt="banner-design">
                                                  </div>
                                                </div>
  
                                                <div class="col-lg-6">
                                                    <div class="banner_design">
                                                      <img src="../assets/images/facebook-banner/banner24.png" alt="banner-design">
                                                    </div>
                                                  </div>
  
                                                  <div class="col-lg-6">
                                                      <div class="banner_design">
                                                        <img src="../assets/images/facebook-banner/banner25.png" alt="banner-design">
                                                      </div>
                                                    </div>
  
                                                    <div class="col-lg-6">
                                                        <div class="banner_design">
                                                          <img src="../assets/images/facebook-banner/banner26.png" alt="banner-design">
                                                        </div>
                                                      </div>
  
                                                      <div class="col-lg-6">
                                                          <div class="banner_design">
                                                            <img src="../assets/images/facebook-banner/banner27.png" alt="banner-design">
                                                          </div>
                                                        </div>
  
                                                        <div class="col-lg-6">
                                                            <div class="banner_design">
                                                              <img src="../assets/images/facebook-banner/banner28.png" alt="banner-design">
                                                            </div>
                                                          </div>
  
                                                          <div class="col-lg-6">
                                                              <div class="banner_design">
                                                                <img src="../assets/images/facebook-banner/banner29.png" alt="banner-design">
                                                              </div>
                                                            </div>
  
                                                            <div class="col-lg-6">
                                                                <div class="banner_design">
                                                                  <img src="../assets/images/facebook-banner/banner30.png" alt="banner-design">
                                                                </div>
                                                              </div>
  
                                                              <div class="col-lg-6">
                                                                  <div class="banner_design">
                                                                    <img src="../assets/images/facebook-banner/banner31.png" alt="banner-design">
                                                                  </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="banner_design">
                                                                      <img src="../assets/images/facebook-banner/banner32.png" alt="banner-design">
                                                                    </div>
                                                                  </div>
                                                            
  



  <!--facebook page banner-->

  <div class="clearfix"></div>

    <!--facebook cover page banner-->

      <!-- <div class="col-lg-6">
          <div class="banner_design">
            <img class="fb_cover_banner" src="../assets/images/fb-cover-page-banner/banner1.png" alt="banner-design">
          </div>
        </div>

        <div class="col-lg-6">
            <div class="banner_design">
              <img src="../assets/images/fb-cover-page-banner/banner2.png" alt="banner-design">
            </div>
          </div> -->


      
<!--facebook cover page banner-->

<div class="col-lg-6">
  <div class="banner_design">
    <img src="../assets/images/banner/banner1.png" alt="banner-design">
  </div>
</div>

<div class="col-lg-6">
    <div class="banner_design">
      <img src="../assets/images/banner/banner2.png" alt="banner-design">
    </div>
  </div>

  <div class="col-lg-6">
      <div class="banner_design">
        <img src="../assets/images/banner/banner3.png" alt="banner-design">
      </div>
    </div>

    <div class="col-lg-6">
        <div class="banner_design">
          <img src="../assets/images/banner/banner4.gif" alt="banner-design">
        </div>
      </div>



      <div class="col-lg-6">
          <div class="banner_design">
            <img src="../assets/images/banner/banner5.png" alt="banner-design">
          </div>
        </div>



    <div class="col-lg-6">
        <div class="banner_design">
          <img src="../assets/images/banner/banner6.png" alt="banner-design">
        </div>
      </div>


    <div class="col-lg-6">
        <div class="banner_design">
          <img src="../assets/images/banner/banner7.png" alt="banner-design">
        </div>
      </div>


    <div class="col-lg-6">
        <div class="banner_design">
          <img src="../assets/images/banner/banner8.png" alt="banner-design">
        </div>
      </div>


      <div class="col-lg-6">
          <div class="banner_design">
            <img src="../assets/images/banner/banner9.png" alt="banner-design">
          </div>
        </div>


        <div class="col-lg-6">
            <div class="banner_design">
              <img src="../assets/images/banner/banner10.png" alt="banner-design">
            </div>
          </div>

          <div class="col-lg-6">
              <div class="banner_design">
                <img src="../assets/images/banner/banner11.png" alt="banner-design">
              </div>
            </div>

            <div class="col-lg-6">
                <div class="banner_design">
                  <img src="../assets/images/banner/banner12.png" alt="banner-design">
                </div>
              </div>


              <div class="col-lg-6">
                  <div class="banner_design">
                    <img src="../assets/images/banner/banner13.png" alt="banner-design">
                  </div>
                </div>


                <div class="col-lg-6">
                    <div class="banner_design">
                      <img src="../assets/images/banner/banner14.png" alt="banner-design">
                    </div>
                  </div>


                  <div class="col-lg-6">
                      <div class="banner_design">
                        <img src="../assets/images/banner/banner15.png" alt="banner-design">
                      </div>
                    </div>


                    <div class="col-lg-6">
                        <div class="banner_design">
                          <img src="../assets/images/banner/banner16.png" alt="banner-design">
                        </div>
                      </div>



                    <div class="col-lg-6">
                        <div class="banner_design">
                          <img src="../assets/images/banner/banner17.jpg" alt="banner-design">
                        </div>
                      </div>



                    <div class="col-lg-6">
                        <div class="banner_design">
                          <img src="../assets/images/banner/banner18.png" alt="banner-design">
                        </div>
                      </div>


                    <div class="col-lg-6">
                        <div class="banner_design">
                          <img src="../assets/images/banner/banner19.jpg" alt="banner-design">
                        </div>
                      </div>

                      <div class="col-lg-6">
                          <div class="banner_design">
                            <img src="../assets/images/banner/banner20-2.png" alt="banner-design">
                          </div>
                        </div>
                    <div class="col-lg-6">
                        <div class="banner_design">
                          <img src="../assets/images/banner/banner20.jpg" alt="banner-design">
                        </div>
                      </div>

                    


                      <div class="col-lg-6">
                          <div class="banner_design">
                            <img src="../assets/images/banner/banner21.jpg" alt="banner-design">
                          </div>
                        </div>

                 

                          <div class="col-lg-6">
                              <div class="banner_design">
                                <img src="../assets/images/banner/banner23.jpg" alt="banner-design">
                              </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="banner_design">
                                  <img src="../assets/images/banner/banner24.jpg" alt="banner-design">
                                </div>
                              </div>


                              <div class="col-lg-6">
                                  <div class="banner_design">
                                    <img src="../assets/images/banner/banner25.jpg" alt="banner-design">
                                  </div>
                                </div>


                                <div class="col-lg-6">
                                    <div class="banner_design">
                                      <img src="../assets/images/banner/banner26.jpg" alt="banner-design">
                                    </div>
                                  </div>


                                  <div class="col-lg-6">
                                      <div class="banner_design">
                                        <img src="../assets/images/banner/banner27.jpg" alt="banner-design">
                                      </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="banner_design">
                                          <img src="../assets/images/banner/banner28.jpg" alt="banner-design">
                                        </div>
                                      </div>

                                   

                                      <div class="col-lg-6">
                                          <div class="banner_design">
                                            <img src="../assets/images/banner/banner29.jpg" alt="banner-design">
                                          </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="banner_design">
                                              <img src="../assets/images/banner/banner22.jpg" alt="banner-design">
                                            </div>
                                          </div>

                                 

  </div>
</div>


<br/>
<br/> 

<router-outlet></router-outlet>   