import { Component } from '@angular/core';

@Component({
  selector: 'app-banking-website',
  templateUrl: './banking-website.component.html',

})
export class BankingWebsiteComponent  {

 

}
