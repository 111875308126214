 <br/>
<br/>

<div class="container">


    <ol class="breadcrumb" style="margin-bottom:0px;">
      <li><a routerLink="/about-us" >About Us</a></li>
      <li class="active">UI Design</li>
    </ol>


    <div class="row">


        <div class="col-lg-6 col-xs-12 text-left">
            <div class="header_text" style="padding:0px;">
                   <div class="text-left">
                           <img src="./assets/images/border-bottom-arrow-down.png"  alt="about-me-sandeep-kumar-saxena">
                       </div>

                      Mobile App Design

           </div>
           </div>





 </div>






  </div>

  <br/>

<div class="container">

<div class="row">
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/splash-screen-n.png" alt="ui design"/>
  </div>
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/welcome-screen.png" alt="ui design"/>
  </div>
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/Login-screen-n.png" alt="ui design"/>
  </div>
</div>

<div class="row">
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/otp-screen.png" alt="ui design"/>
  </div>
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/Forgot-password-screen-n.png" alt="ui design"/>
  </div>
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/create-screen-n.png" alt="ui design"/>
  </div>
</div>

<div class="row">
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/profile-screen.png" alt="ui design"/>
  </div>
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/menu-screen.png" alt="ui design"/>
  </div>
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/my-coures-screen.png" alt="ui design"/>
  </div>
</div>

<div class="row">
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/Explore-coures-screen.png" alt="ui design"/>
  </div>
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/course details/cources_lesson-screen.png" alt="ui design"/>
  </div>
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/course details/course_details_screen_price.png" alt="ui design"/>
  </div>
</div>

<div class="row">
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/course details/course_PDF_option_screen.png" alt="ui design"/>
  </div>
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/course details/course_video_play.png" alt="ui design"/>
  </div>
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/course details/session.png" alt="ui design"/>
  </div>
</div>

<div class="row">
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/checkout-process-screens/purchase- history-screen.png" alt="ui design"/>
  </div>
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/checkout-process-screens/checkout.png" alt="ui design"/>
  </div>
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/checkout-process-screens/add_delivery_address.png" alt="ui design"/>
  </div>
</div>

<div class="row">
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/checkout-process-screens/add_new_address.png" alt="ui design"/>
  </div>
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/checkout-process-screens/payment_method.png" alt="ui design"/>
  </div>
  <div class="col-md-4 aceApp">
    <img class="img-responsive" src="../assets/images/app-ui/checkout-process-screens/congratulation.png" alt="ui design"/>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
    <div class="pro_name2" style="border: 0px; padding: 0px;">Mobile App URL: <br><span><a href="https://play.google.com/store/apps/details?id=com.app.tendigi&hl=en" target="_blank">on google play store 10digi.com</a></span></div>
</div>
</div>


 


<div class="tendigi_new_app">
    <!-- new 10dig customer app -->

    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/splash01.png"  alt="ui-design">
    </div>

    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/splash02.png"  alt="ui-design">
    </div>

    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/splash03.png"  alt="ui-design">
    </div>

    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/login01.png"  alt="ui-design">
    </div>

    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/login02.png"  alt="ui-design">
    </div>

    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/login03.png"  alt="ui-design">
    </div>

    
    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/my-account.png"  alt="ui-design">
    </div>

    
    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/my-account02.png"  alt="ui-design">
    </div>


    
    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/edit-password.png"  alt="ui-design">
    </div>

    
    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/edit-password02.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/home00008.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/home-0010000.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/home-revam002-icon.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/home-slide002.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/home-slide003.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/menu04.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/menu-white.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/pre.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/pre-list.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/post.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/slider01.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/slider02.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/slider03.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/slider05.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/coupon-code.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/coupon-code.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/coupon-code.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/coupon-code.png"  alt="ui-design">
    </div>

    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/payment.png"  alt="ui-design">
    </div>

    
    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/status-bar.png"  alt="ui-design">
    </div>


    
    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/my-order-staus.png"  alt="ui-design">
    </div>


    
    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/payment.png"  alt="ui-design">
    </div>


    
    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/payment.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/thank-new03.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/mobile_accessories.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/mobile-list.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/m-charger-description.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/product-description.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/watch.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/fillter.png"  alt="ui-design">
    </div>

    
    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/search.png"  alt="ui-design">
    </div>

    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/my-order.png"  alt="ui-design">
    </div>


    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/my-order02.png"  alt="ui-design">
    </div>

    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/thank-new.png"  alt="ui-design">
    </div>

    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/help03.png"  alt="ui-design">
    </div>

    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/support.png"  alt="ui-design">
    </div>

    <div class="mobile_app_design">
      <img src="../assets/images/tendigi_app/support-orange.png"  alt="ui-design">
    </div>

  </div>


    <div class="card_columns_ui" style="width: 100%;">
    <!-- new 10dig customer app -->




  <div class="mobile_app_design">
  <img src="../assets/images/10digi-ui/10digi-app-01.jpg"  alt="ui-design">
</div>



<div class="mobile_app_design">
    <img src="../assets/images/10digi-ui/10digi-app-02.jpg"  alt="ui-design">
</div>



<div class="mobile_app_design">
    <img src="../assets/images/10digi-ui/10digi-app-03.jpg"  alt="ui-design">
</div>



<div class="mobile_app_design">
    <img src="../assets/images/10digi-ui/10digi-app-04.jpg"  alt="ui-design">
</div>



<div class="mobile_app_design">
    <img src="../assets/images/10digi-ui/10digi-app-05.jpg"  alt="ui-design">
</div>






          <div class="mobile_app_design">
            <img src="../assets/images/10digi-ui/10digi-app-06.jpg"  alt="ui-design">
        </div>




            <div class="mobile_app_design">
              <img src="../assets/images/10digi-ui/10digi-app-07.jpg"  alt="ui-design">
          </div>



              <div class="mobile_app_design">
                <img src="../assets/images/10digi-ui/10digi-app-08.jpg"  alt="ui-design">
            </div>



                <div class="mobile_app_design">
                  <img src="../assets/images/10digi-ui/10digi-app-09.jpg"  alt="ui-design">
              </div>



                  <div class="mobile_app_design">
                    <img src="../assets/images/10digi-ui/10digi-app-10.jpg"  alt="ui-design">
                </div>



                    <div class="mobile_app_design">
                      <img src="../assets/images/10digi-ui/10digi-app-11.jpg"  alt="ui-design">
                  </div>





                    <div class="mobile_app_design">
                      <img src="../assets/images/10digi-ui/10digi-app-12.jpg"  alt="ui-design">
                  </div>



                      <div class="mobile_app_design">
                        <img src="../assets/images/10digi-ui/10digi-app-13.jpg"  alt="ui-design">
                    </div>



                        <div class="mobile_app_design">
                          <img src="../assets/images/10digi-ui/10digi-app-14.jpg"  alt="ui-design">
                      </div>




                        <div class="mobile_app_design">
                          <img src="../assets/images/10digi-ui/10digi-app-15.jpg"  alt="ui-design">
                      </div>





                        <div class="mobile_app_design">
                          <img src="../assets/images/10digi-ui/10digi-app-16.jpg"  alt="ui-design">
                      </div>





                        <div class="mobile_app_design">
                          <img src="../assets/images/10digi-ui/10digi-app-17.jpg"  alt="ui-design">
                      </div>
                    </div>


<br/>
<div class="pro_name2">Mobile App URL: <br><span><a href="https://play.google.com/store/apps/details?id=com.app.tendigi&hl=en" target="_blank">on google play store 10digi.com</a></span></div>

</div>





<br/>
<br/>

<router-outlet></router-outlet>