import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emailer-design',
  templateUrl: './emailer-design.component.html',
  styles: []
})
export class EmailerDesignComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
