<br/>
<br/>




<div class="container">

 
    <ol class="breadcrumb" style="margin-bottom:0px;"> 
      <li><a routerLink="/about-us" >About Us</a></li>
      <li class="active">Zooneto Info Soft</li>        
    </ol>
    <div class="row">
    <div class="col-md-12 text-left">
                                          <div class="header_text" style="padding:0px;">
                                                 <div class="text-left">
                                                         <img src="./assets/images/border-bottom-arrow-down.png"  alt="about-me-sandeep-kumar-saxena">
                                                     </div>
                                                    
                                                     Previous Project
                                                       
                                         </div>
                                         </div> 
                                        </div>
  </div>

  <br/>


<div class="container">
  <div class="row">

<div class="col-lg-4">
<div class="projectListing">
<div class="img_overlay">
  <div class="project_title text-center">Karsoo</div>
    <div class="col-md-6 col-md-offset-3 col-sm-offset-6 text-center project_details_btn">
        <a routerLink="/karsoo" class="view_my_profile">View Details</a>
    </div> 
</div>  
<img src="../assets/images/projectimg7.png" alt="projectimg1">
</div>
</div>



<div class="col-lg-4">
  <div class="projectListing">
  <div class="img_overlay">
    <div class="project_title text-center">Goldmine Developer</div>
      <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
          <a routerLink="/goldmine-developer" class="view_my_profile">View Details</a>
      </div> 
  </div>  
  <img src="../assets/images/projectimg14.jpg" alt="projectimg1">
  </div>
  </div>                  



  <div class="col-lg-4">
    <div class="projectListing">
    <div class="img_overlay">
      <div class="project_title text-center">PropCASA</div>
        <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
            <a routerLink="/propcasa" class="view_my_profile">View Details</a>
        </div> 
    </div>  
    <img src="../assets/images/projectimg15.jpg" alt="projectimg1">
    </div>
    </div>




    <div class="col-lg-4">
      <div class="projectListing">
      <div class="img_overlay">
        <div class="project_title text-center">Indian Cuisine Classes
          </div>
          <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
              <a routerLink="/journagetravels" class="view_my_profile">View Details</a>
          </div> 
      </div>  
      <img src="../assets/images/full-screen03-2.png" alt="projectimg1">
      </div>
      </div>
  
  
      <div class="col-lg-4">
        <div class="projectListing">
        <div class="img_overlay">
          <div class="project_title text-center">Fernhill Resort Chail</div>
            <div class="col-md-6 col-md-offset-3 col-sm-offset-6 text-center project_details_btn">
                <a routerLink="/thegoldempire" class="view_my_profile">View Details</a>
            </div> 
        </div>  
        <img src="../assets/images/full-screen04-2.png" alt="projectimg1">
        </div>
        </div>



<div class="col-lg-4">
<div class="projectListing">
<div class="img_overlay">
  <div class="project_title text-center">Anam Tutors</div>
    <div class="col-md-6 col-md-offset-3 col-sm-offset-6 text-center project_details_btn">
        <a routerLink="/searchmyghar" class="view_my_profile">View Details</a>
    </div> 
</div>  
<img src="../assets/images/full-screen05-2.png" alt="projectimg1">
</div>
</div>

      <div class="col-lg-4">
        <div class="projectListing">
        <div class="img_overlay">
          <div class="project_title text-center">Adebooking</div>
            <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                <a routerLink="/adebooking" class="view_my_profile">View Details</a>
            </div> 
        </div>  
        <img src="../assets/images/projectimg19.jpg" alt="projectimg1">
        </div>
        </div>

        <div class="col-lg-4">
          <div class="projectListing">
          <div class="img_overlay">
            <div class="project_title text-center">Zooneto Infosoft</div>
              <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                  <a routerLink="/zoonetoinfosoft" class="view_my_profile">View Details</a>
              </div> 
          </div>  
          <img src="../assets/images/projectimg20.png" alt="projectimg1">
          </div>
          </div>


          
          <div class="col-lg-4">
            <div class="projectListing">
            <div class="img_overlay">
              <div class="project_title text-center">Prateek Grand City</div>
                <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                    <a routerLink="/prateek-grand-city" class="view_my_profile">View Details</a>
                </div> 
            </div>  
            <img src="../assets/images/projectimg21.jpg" alt="projectimg1">
            </div>
            </div> 
   


            <div class="col-lg-4">
                <div class="projectListing">
                <div class="img_overlay">
                  <div class="project_title text-center">Gaur Sidhartham</div>
                    <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                        <a routerLink="/gaursidhartham" class="view_my_profile">View Details</a>
                    </div> 
                </div>  
                <img src="../assets/images/projectimg11.jpg" alt="projectimg1">
                </div>
                </div>

                <div class="col-lg-4">
                    <div class="projectListing">
                    <div class="img_overlay">
                      <div class="project_title text-center">Noida Realty Forum</div>
                        <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                            <a routerLink="/noidarealtyforum" class="view_my_profile">View Details</a>
                        </div> 
                    </div>  
                    <img src="../assets/images/projectimg12.jpg" alt="projectimg1">
                    </div>
                    </div>








                    <div class="col-lg-4">
                      <div class="projectListing">
                      <div class="img_overlay">
                        <div class="project_title text-center">3R CASA</div>
                          <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                              <a routerLink="/three-rcasa" class="view_my_profile">View Details</a>
                          </div> 
                      </div>  
                      <img src="../assets/images/projectimg13.jpg" alt="projectimg1">
                      </div>
                      </div>


    
                    
    
   
                          <div class="col-lg-4">
                            <div class="projectListing">
                            <div class="img_overlay">
                              <div class="project_title text-center">Sundawn Entertainment</div>
                                <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                                    <a routerLink="/sundawn" class="view_my_profile">View Details</a>
                                </div> 
                            </div>  
                            <img src="../assets/images/projectimg16.jpg" alt="projectimg1">
                            </div>
                            </div>                       

                            <div class="col-lg-4">
                              <div class="projectListing">
                              <div class="img_overlay">
                                <div class="project_title text-center">Wisteria Weddings</div>
                                  <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                                      <a routerLink="/wisteria-wedding" class="view_my_profile">View Details</a>
                                  </div> 
                              </div>  
                              <img src="../assets/images/projectimg17.jpg" alt="projectimg1">
                              </div>
                              </div>

                              <div class="col-lg-4">
                                <div class="projectListing">
                                <div class="img_overlay">
                                  <div class="project_title text-center">Om Innovation</div>
                                    <div class="col-md-6 col-md-offset-3 text-center project_details_btn">
                                        <a routerLink="/oninnovation" class="view_my_profile">View Details</a>
                                    </div> 
                                </div>  
                                <img src="../assets/images/projectimg18.jpg" alt="projectimg1">
                                </div>
                                </div>

                                
  </div>
</div>


<router-outlet></router-outlet> 